import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { About }  from "./components/About";
import { Services } from "./components/Services";
import { Blogs } from "./components/Blogs";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import Axios from "axios";
import { useEffect, useState } from 'react';

const App=()=>{
  const [data,setData]=useState();
  const getData=async()=>{
    const response=await Axios.get("http://localhost:5000/getData");
    setData(response.data);

  }
  useEffect(()=>{
    getData()
  },[]);
  return (
    
      <div className="App">
      <NavBar />
      <Banner />
      <About />
      <Services />
      <Blogs />
      <Contact />
      <Footer />
    </div>
    
    
  );
} 


export default App;