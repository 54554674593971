
import { Container, Row, Col } from "react-bootstrap";
//import headerImg from "../assets/img/header-img.svg";
import abImg from "../assets/img/ab-img.jpg"
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const About = () => {

  return (
    <section className="About" id="about">
    <Container>
      <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={7}>
          <TrackVisibility>
            {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <span className="tagline">About Us</span>
              <p>{`SERVICES`}</p>
              <p>{`Innovative Solutions: Tailored solutions to meet your unique needs.`} </p>
              <p>{`Expertise: Specializing in UI/UX design, e-commerce, video editing, 3D modeling, digital marketing, and custom software and mobile app development.`}</p>
              <p>{`Creative Solutions: From concept to execution, we bring creativity and innovation to every project.`}</p>
              <p>{`COMMUNITY`}</p>
              <p>{`Vibrant Community: Join our inclusive and supportive community of creative minds.`}</p>
              <p>{`Collaborative Environment: Engage in collaborative projects, knowledge-sharing sessions, and networking events.`}</p>
              <p>{`Opportunities for Growth: Explore opportunities for personal and professional growth through our dynamic community.`}</p>
              <p>{`WHY US`}</p>
              <p>{`Experience: With years of experience, we deliver high-quality solutions that exceed expectations.`}</p>
              <p>{`Dedicated Team: Our dedicated team of experts is committed to your success.`}</p>
              <p>{`Innovation: We push the boundaries of innovation to deliver cutting-edge solutions that drive results.`}</p>
              
                <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button>
            </div>}
          </TrackVisibility>
        </Col>
        <Col xs={12} md={6} xl={5}>
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                <img src={abImg} alt="Header Img"/>
              </div>}
          </TrackVisibility>
        </Col>
      </Row>
    </Container>
  </section>
  )
}