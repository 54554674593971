import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { Card } from "react-bootstrap";
import {Button} from "react-bootstrap";
import uiuximg from "../assets/img/uiux.png"
import appdev from "../assets/img/appdev.png"
import model from "../assets/img/model.png"
import vid from "../assets/img/vid.png"
export const Services = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Services we offer</h2>
                        
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                            <Card style={{ width: '18rem' ,height:'100%' ,backgroundColor: '#b1e5ee',
                              Color:'#000'
                            }}>
                              
                              <Card.Body >
                              <Card.Img variant='top' src={appdev} style={{ width: '30%', height: 'auto' }}></Card.Img>
                                <Card.Text style={{
                              Color:'black'
                            }}>
                                  Bring your app ideas to life with our cutting-edge mobile development services. Our team specializes in crafting user-friendly and high-performance applications for both iOS and Android platforms. Whether you need a robust enterprise solution or an engaging consumer app, we provide customized development services to meet your unique requirements. Discover how our mobile app development can transform your business and enhance user experiences with IdeationX.
                                </Card.Text>
                                <Button style={{backgroundColor: 'white',
                                  color:'Black'
                                }} variant="primary">Learn More</Button>
                              </Card.Body>
                            </Card>
                            </div>
                            <div className="item">
                            <Card style={{ width: '18rem' ,height:'100%',backgroundColor: '#b1e5ee',
                              Color:'#000'
                            }}>
                              
                              <Card.Body >
                                <Card.Img variant='top' src={uiuximg} style={{ width: '30%', height: 'auto' }}></Card.Img>
                                <Card.Text style={{
                              Color:'black'
                            }}>
                                  Enhance your digital presence with our specialized UI/UX design services. At IdeationX, we focus on creating interfaces that are not only visually appealing but also highly functional. Our team carefully analyzes user behavior to design intuitive and engaging experiences. From concept to completion, we ensure every design element aligns with your brand's goals. Improve user satisfaction and interaction with our tailored UI/UX design solutions.
                                </Card.Text>
                                <Button style={{backgroundColor: 'white',
                                  color:'Black'
                                }} variant="primary">Learn More</Button>
                              </Card.Body>
                            </Card>
                            </div>
                            <div className="item">
                            <Card style={{ width: '18rem' ,height:'100%',backgroundColor: '#b1e5ee',
                              Color:'#000'
                            }}>
                              
                              <Card.Body >
                              <Card.Img variant='top' src={model} style={{ width: '30%', height: 'auto' }}></Card.Img>
                                <Card.Text style={{
                              Color:'black'
                            }}>
                              Transform your concepts into detailed 3D models with our professional 3D modeling services. At IdeationX, we specialize in creating accurate and realistic models for various industries, including product design, architecture, and entertainment. Our skilled team uses advanced techniques to bring your ideas to life, ensuring precision and high quality. Enhance your projects with our comprehensive 3D modeling solutions and make your visions tangible.
                                   </Card.Text>
                                <Button style={{backgroundColor: 'white',
                                  color:'Black'
                                }} variant="primary">Learn More</Button>
                              </Card.Body>
                            </Card>
                            </div>
                            <div className="item">
                            <Card style={{ width: '18rem' ,height:'100%',backgroundColor: '#b1e5ee',
                              Color:'#000'
                            }}>
                              
                              <Card.Body >
                              <Card.Img variant='top' src={vid} style={{ width: '30%', height: 'auto' }}></Card.Img>

                                <Card.Text style={{
                              Color:'black'
                            }}>
                                  Transform your raw footage into captivating content with our expert video editing services. Whether for business or personal projects, our skilled editors enhance your videos with seamless transitions, stunning effects, and professional polish. Explore our video editing solutions and elevate your storytelling with IdeationX.
                                </Card.Text>
                                <Button style={{backgroundColor: 'white',
                                  color:'Black'
                                }} variant="primary">Learn More</Button>
                              </Card.Body>
                            </Card>
                            </div>
                           
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="" />
    </section>
  )
}